import "./shared/components/map-ruler-button.js";
import processNewLocation from './modalLocationAdd.js';
import { isSmartPhone } from './platform.js';

$j('body')
        .on('map-save-location', (event) => {
            let {p} = event.detail;
            $j('pw-map-add-location-modal').prop({'lat': p.lat, 'lon': p.lon, 'rejectSubmit': false});
            $j('#add-location-modal').each((key, element) => {
                if(!element.open) element.showModal();
            });
            // Experimental dynamic import of dependency.
            // Unfortunately dev and prod need different code :(
            if (window.importShim) {
                importShim("./components/map-add-location-modal.js", import.meta.url);
            } else {
                import("./components/map-add-location-modal.js");
            }
        })
        .on('map-add-location-modal-close', () => {
            $j('#add-location-modal').each((key, element) => {
                if(element.open) element.close();
            });
        })
        .on('new-location-added', processNewLocation)
        .on('ruler-disabled', () => {
            if (!isSmartPhone) {
                $j('#show-hide-table-buttons').show();
                $j('.observations-toggle-container-container').show();
                $j('.observations-zoom-message-container-container').show();
            }
        })
        .on('add-point', () => {
            if (PWGMap.ruler.enabled) PWGMap.ruler.createPoint(PWGMap.map1.getCentre());
        })
        .on('remove-point', () => {
            if (PWGMap.ruler.enabled) PWGMap.ruler.removeLastPoint();
        })
        .on('end-ruler', () => {
            if (PWGMap.ruler.enabled) PWGMap.ruler.pauseRuler();
        });
